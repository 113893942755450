import React from 'react';
import { FaHandshake, FaExchangeAlt, FaChartLine, FaLock } from 'react-icons/fa';
import Reviews from '../components/Reviews';

const WhyUs = () => {
  return (
    <>
    <div className="max-w-7xl mx-auto py-16 px-6 lg:px-8">
      <h2 className="text-3xl md:text-4xl font-extrabold text-gray-900 text-center">Why Choose Us?</h2>
      <p className="mt-4 text-lg text-gray-600 text-center">
        TheWholesaleKart is revolutionizing the inventory liquidation industry by providing a transparent, efficient, and direct platform for sellers and buyers.
      </p>

      <div className="mt-12 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
        <div className="text-center">
          <FaLock className="text-indigo-500 text-6xl mx-auto" />
          <h3 className="mt-4 text-xl font-semibold text-gray-900">Transparency</h3>
          <p className="mt-2 text-gray-600">
            We bring clarity to the unorganized liquidation industry, ensuring transparent pricing and stock conditions.
          </p>
        </div>

        <div className="text-center">
          <FaHandshake className="text-green-500 text-6xl mx-auto" />
          <h3 className="mt-4 text-xl font-semibold text-gray-900">Direct Connections</h3>
          <p className="mt-2 text-gray-600">
            Our platform connects sellers directly with buyers, eliminating intermediaries and simplifying transactions.
          </p>
        </div>

        <div className="text-center">
          <FaExchangeAlt className="text-blue-500 text-6xl mx-auto" />
          <h3 className="mt-4 text-xl font-semibold text-gray-900">Real-Time Quotations</h3>
          <p className="mt-2 text-gray-600">
            Sellers can receive real-time quotations for their dead stock, helping them make informed decisions quickly.
          </p>
        </div>

        <div className="text-center">
          <FaChartLine className="text-yellow-500 text-6xl mx-auto" />
          <h3 className="mt-4 text-xl font-semibold text-gray-900">Maximized Reach and Value</h3>
          <p className="mt-2 text-gray-600">
            We help sellers maximize their inventory's value and reach, while buyers benefit from competitive pricing.
          </p>
        </div>
      </div>

      <div className="mt-16 text-center">
        <a
          href="/signup"
          className="inline-block bg-yellow-400 text-white px-8 py-3 text-lg font-medium hover:bg-yellow-500"
        >
          Get Started
        </a>
      </div>
    </div>
    <Reviews/>
    </>
  );
};

export default WhyUs;
