import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';

const ContactPage = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' });
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) newErrors.name = "Name is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.message) newErrors.message = "Message is required";
    return newErrors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length === 0) {
      toast.success('Message sent successfully!');
      setFormData({ name: '', email: '', message: '' });  
    } else {
      setErrors(validationErrors);
      toast.error('Please fill in all required fields.');
    }
  };

  return (
    <div className="max-w-4xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
      <h2 className="text-3xl md:text-4xl font-extrabold text-center text-gray-900 mb-4"><span className='text-yellow-400'>Get In Touch </span>With Us</h2>

      <form onSubmit={handleSubmit} className="bg-white shadow-lg rounded-lg p-8 space-y-6">
        <div className="flex flex-col">
          <label htmlFor="name" className="text-lg font-semibold text-gray-800">Name</label>
          <input
            type="text"
            id="name"
            value={formData.name}
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            className={`mt-2 p-3 border-2 rounded-lg ${
              errors.name ? 'border-red-500' : 'border-gray-300'
            } focus:outline-none focus:ring-2 focus:ring-indigo-500`}
            placeholder="Your Name"
          />
          {errors.name && <p className="text-red-500 text-sm mt-1">{errors.name}</p>}
        </div>

        <div className="flex flex-col">
          <label htmlFor="email" className="text-lg font-semibold text-gray-800">Email</label>
          <input
            type="email"
            id="email"
            value={formData.email}
            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
            className={`mt-2 p-3 border-2 rounded-lg ${
              errors.email ? 'border-red-500' : 'border-gray-300'
            } focus:outline-none focus:ring-2 focus:ring-indigo-500`}
            placeholder="Your Email"
          />
          {errors.email && <p className="text-red-500 text-sm mt-1">{errors.email}</p>}
        </div>

        <div className="flex flex-col">
          <label htmlFor="message" className="text-lg font-semibold text-gray-800">Message</label>
          <textarea
            id="message"
            value={formData.message}
            onChange={(e) => setFormData({ ...formData, message: e.target.value })}
            className={`mt-2 p-3 h-32 border-2 rounded-lg ${
              errors.message ? 'border-red-500' : 'border-gray-300'
            } focus:outline-none focus:ring-2 focus:ring-indigo-500`}
            placeholder="Your Message"
          />
          {errors.message && <p className="text-red-500 text-sm mt-1">{errors.message}</p>}
        </div>

        <button
          type="submit"
          className="w-full bg-yellow-400 p-3 text-lg font-medium hover:bg-yellow-500 transition-colors duration-300"
        >
          Send Message
        </button>
      </form>

      <ToastContainer position="bottom-left" autoClose={3000} hideProgressBar />
    </div>
  );
};

export default ContactPage;
