import React from 'react';
import { FaShippingFast, FaEye, FaStoreAlt, FaHandshake, FaBoxes, FaWarehouse } from 'react-icons/fa';

const WhatWeDo = () => {
  return (
    <section className="bg-gray-100 py-14 md:py-11">
      <div className="container mx-auto px-6 md:px-40">
        <h2 className="text-3xl md:text-4xl font-extrabold text-gray-900 text-center mb-12 tracking-wide">
          What We Do?
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
          <div className="bg-white p-6 shadow-lg hover:shadow-2xl hover:scale-105 transform transition duration-300 ease-in-out">
            <FaBoxes className="w-14 h-14 mb-4 text-yellow-400 mx-auto"/>
            <h3 className="text-xl font-semibold text-gray-800 text-center mb-3">Stock Liquidation</h3>
            <p className="text-gray-600 text-center">
              Developing an ecosystem for inventory holders to maximize their stock liquidation and provide seamless transactions.
            </p>
          </div>
          <div className="bg-white p-6 shadow-lg hover:shadow-2xl hover:scale-105 transform transition duration-300 ease-in-out">
            <FaEye className="w-14 h-14 mb-4 text-yellow-400 mx-auto"/>
            <h3 className="text-xl font-semibold text-gray-800 text-center mb-3">Brand Visibility</h3>
            <p className="text-gray-600 text-center">
              Maximizing offline visibility of private label brands and promoting brand visibility across multiple geographies.
            </p>
          </div>
          <div className="bg-white p-6 shadow-lg hover:shadow-2xl hover:scale-105 transform transition duration-300 ease-in-out">
            <FaStoreAlt className="w-14 h-14 mb-4 text-yellow-400 mx-auto"/>
            <h3 className="text-xl font-semibold text-gray-800 text-center mb-3">Retail Buying Patterns</h3>
            <p className="text-gray-600 text-center">
              Emulsifying and innovating the buying patterns of the retail market, helping to optimize retail strategies.
            </p>
          </div>
          <div className="bg-white p-6 shadow-lg hover:shadow-2xl hover:scale-105 transform transition duration-300 ease-in-out">
            <FaHandshake className="w-14 h-14 mb-4 text-yellow-400 mx-auto"/>
            <h3 className="text-xl font-semibold text-gray-800 text-center mb-3">Transparency</h3>
            <p className="text-gray-600 text-center">
              Off-roading channel partners by directly connecting buyers with inventory holders, promoting transparency in the process.
            </p>
          </div>
          <div className="bg-white p-6 shadow-lg hover:shadow-2xl hover:scale-105 transform transition duration-300 ease-in-out">
            <FaShippingFast className="w-14 h-14 mb-4 text-yellow-400 mx-auto"/>
            <h3 className="text-xl font-semibold text-gray-800 text-center mb-3">End-to-End Solutions</h3>
            <p className="text-gray-600 text-center">
              Offering end-to-end solutions from stock inspection to liquidation, simplifying the process for inventory holders.
            </p>
          </div>
          <div className="bg-white p-6 shadow-lg hover:shadow-2xl hover:scale-105 transform transition duration-300 ease-in-out">
            <FaWarehouse className="w-14 h-14 mb-4 text-yellow-400 mx-auto"/>
            <h3 className="text-xl font-semibold text-gray-800 text-center mb-3">Surplus Goods Realization</h3>
            <p className="text-gray-600 text-center">
              Helping inventory holders sell surplus goods at the best possible market prices.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhatWeDo;
