import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

// Desktop Banners
import banner1 from '../assets/banner1.jpg';
import banner2 from '../assets/banner2.jpg';
import banner3 from '../assets/banner3.jpg';
import banner4 from '../assets/banner4.jpg';

// Mobile Banners
import mobileBanner1 from '../assets/mobile/b1.jpg';
import mobileBanner2 from '../assets/mobile/b2.jpg';
import mobileBanner3 from '../assets/mobile/b3.jpg';
import mobileBanner4 from '../assets/mobile/b4.jpg';

const Hero = () => {
  return (
    <div className="bg-gray-100">
      {/* Desktop Banners */}
      <div className="hidden lg:block">
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          className="mySwiper"
        >
          <SwiperSlide><img src={banner1} alt="Banner 1" className="w-full" /></SwiperSlide>
          <SwiperSlide><img src={banner2} alt="Banner 2" className="w-full" /></SwiperSlide>
          <SwiperSlide><img src={banner3} alt="Banner 3" className="w-full" /></SwiperSlide>
          <SwiperSlide><img src={banner4} alt="Banner 4" className="w-full" /></SwiperSlide>
        </Swiper>
      </div>

      {/* Mobile Banners */}
      <div className="block lg:hidden">
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          className="mySwiper"
        >
          <SwiperSlide><img src={mobileBanner1} alt="Mobile Banner 1" className="w-full" /></SwiperSlide>
          <SwiperSlide><img src={mobileBanner2} alt="Mobile Banner 2" className="w-full" /></SwiperSlide>
          <SwiperSlide><img src={mobileBanner3} alt="Mobile Banner 3" className="w-full" /></SwiperSlide>
          <SwiperSlide><img src={mobileBanner4} alt="Mobile Banner 4" className="w-full" /></SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default Hero;
