import React from 'react';
import brand1 from '../assets/miniclub.png';
import brand2 from '../assets/ancestry.webp';
import brand3 from '../assets/nautinati.png';
import brand4 from '../assets/chicco.jpg';
import brand5 from '../assets/truebrown.jpeg';
import brand6 from '../assets/madame.png';

const Brands = () => {
  return (
    <section className="bg-white md:py-6">
      <div className="container mx-auto text-center md:-mt-11">
        {/* <h2 className="text-3xl md:text-4xl font-extrabold mb-6">Brands Associated With</h2> */}
        <div className="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-6 gap-2 items-center justify-center">
          <div className="brand-logo">
            <img
              src={brand1}
              alt="Brand 1"
              className="w-32 mx-auto transform transition duration-500"
            />
          </div>
          <div className="brand-logo">
            <img
              src={brand2}
              alt="Brand 2"
              className="w-32 mx-auto transform transition duration-500"
            />
          </div>
          <div className="brand-logo">
            <img
              src={brand3}
              alt="Brand 3"
              className="w-32 mx-auto transform transition duration-500"
            />
          </div>
          <div className="brand-logo">
            <img
              src={brand4}
              alt="Brand 4"
              className="w-32 mx-auto transform transition duration-500"
            />
          </div>
          <div className="brand-logo">
            <img
              src={brand5}
              alt="Brand 5"
              className="w-32 mx-auto transform transition duration-500"
            />
          </div>
          <div className="brand-logo">
            <img
              src={brand6}
              alt="Brand 6"
              className="w-32 mx-auto transform transition duration-500"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Brands;
