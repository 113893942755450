import buyer from "../assets/buyer-guide.png"

export const BuyerGuide = () => {
  return (
    <div className="bg-gray-100">

    <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row items-center">

      <div className="md:w-1/2 md:pr-8 text-left">
        <h2 className="text-3xl md:text-4xl font-extrabold text-gray-900 mb-4">Buyer Guide</h2>
        <p className="text-lg text-gray-600 border-b-4 border-yellow-400">
        Purchasing and sourcing goods is a key part of any business, and Value Shoppe helps by providing up-to-date stock 
        availability at competitive prices. We know it's difficult for retailers and wholesalers to track stock in real-time, 
        so our platform allows resellers to buy directly from the source. Orders can be picked up from our distribution centers 
        or drop shipped to any location. We're always expanding and welcome businesses of all sizes to join our affiliate list. 
        With Value Shoppe, you’ll find great inventory at low wholesale prices, and we guarantee honest and reliable transactions.
        </p>
      </div>

      <div className="md:w-1/2 mt-8 md:mt-0 md:pl-8">
        <img src={buyer} alt="Buyer Guide" className="w-full h-auto" />
      </div>
    </div>
    </div>

  )
}
