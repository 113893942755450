import React from 'react';
import image from "../assets/image1.jpg"
import { Link } from 'react-router-dom';

const HomeWhyUs = () => {
  return (
    <section className="bg-white py-10 md:py-36">
      <div className="container mx-auto px-6 md:px-40">
        <div className="md:flex md:items-center">
          <div className="md:w-1/2">
            <img src={image} alt="Why Us" className="shadow-lg"/>
          </div>
          <div className="md:w-1/2 md:pl-12 mt-8 md:mt-0">
            <h2 className="text-3xl md:text-4xl font-extrabold text-gray-900 mb-4">Why Choose Us?</h2>
            <p className="text-gray-600 text-lg mb-6">
             At TheWholeSaleKart, we simplify the inventory liquidation process, offering sellers real-time quotations
             and connecting them directly with buyers, eliminating unnecessary middlemen. Our platform ensures a 
             transparent, hassle-free experience for both parties, streamlining negotiations, payments, and 
             stock dispatch. With a focus on maximizing visibility and providing the best value for unsold 
             inventory, we help businesses turn dead stock into cash effortlessly.
            </p>
            <Link to="why-us" className="bg-yellow-400 text-white font-semibold py-3 px-8 hover:bg-yellow-500">
                Learn More
            </Link>

          </div>
        </div>
      </div>
    </section>
  );
};

export default HomeWhyUs;
