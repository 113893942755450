import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div className="container mx-auto px-6 lg:px-40 py-12 bg-gray-100">
      <div className="bg-white shadow-lg p-6 lg:p-10">
        <h1 className="text-3xl md:text-4xl font-extrabold mb-8">Privacy Policy</h1>
        <div className="text-gray-700 space-y-8">
          <p>
            Welcome to TheWholesaleKart. We are committed to protecting the privacy of our clients, users, and website visitors. This Privacy Policy outlines our practices regarding the collection, use, and disclosure of your personal information when you visit our website or engage with us in other ways.
          </p>
          <div>
            <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
            <p>Welcome to TheWholesaleKart, ("we," "our," "us"). This Privacy Policy explains how we collect, use, disclose, and protect your information when you visit our website <span className='text-yellow-400'>thewholesalekart.com</span> (the "Site"). Please read this policy carefully to understand our views and practices regarding your personal data and how we will treat it.</p>
          </div>
          <div>
            <h2 className="text-2xl font-semibold mb-4">2. Information we collect</h2>
            <ul className="list-disc ml-6 mt-2 space-y-2">
              <li>
                <strong>Personal Information:</strong> We may collect personal information that you provide directly to us when you register, fill out forms, or engage in transactions on the Site. This information may include your name, email address, phone number, business information, and payment details.
              </li>
              <li>
                <strong>Usage data:</strong> We collect information automatically as you navigate through the Site. This may include usage details, IP addresses, and information collected through cookies and other tracking technologies.
              </li>
            </ul>
          </div>
          <div>
            <h2 className="text-2xl font-semibold mb-4">3. How we use your information</h2>
            <ul className="list-disc ml-6 mt-2 space-y-2">
              <li>
                <strong>To provide services:</strong> We use your information to facilitate transactions, provide customer support, and communicate with you.
              </li>
              <li>
                <strong>To improve our site:</strong> We may use your information to analyze how you use our Site to enhance your user experience.
              </li>
              <li>
                <strong>For marketing:</strong> With your consent, we may use your personal information to send you promotional content.
              </li>
            </ul>
          </div>
          <div>
            <h2 className="text-2xl font-semibold mb-4">4. How we share your information</h2>
            <ul className="list-disc ml-6 mt-2 space-y-2">
              <li>
                <strong>Third-party service providers:</strong> We may share your information with third-party service providers who assist us in operating our Site, conducting our business, or serving our users.
              </li>
              <li>
                <strong>Legal requirements:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities.
              </li>
            </ul>
          </div>
          <div>
            <h2 className="text-2xl font-semibold mb-4">5. Data security</h2>
            <p>We take the security of your personal information seriously and use reasonable administrative, technical, and physical measures to protect your data.</p>
          </div>
          <div>
            <h2 className="text-2xl font-semibold mb-4">6. Your rights</h2>
            <p>
            You have the right to access, correct, or delete your personal information. You may also withdraw your consent to the processing of your data at any time.
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-semibold mb-4">7. Changes to this Privacy Policy</h2>
            <p>
            We may update our Privacy Policy from time to time. Any changes will be posted on this page with an updated revision date.
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-semibold mb-4">7. Contact us</h2>
            <p>
            If you have any questions or concerns about this Privacy Policy, please contact us at 
            </p>
            <p className="mt-2">
              <strong>TheWholesaleKart</strong><br />
              BL-47, Basement, Sector 116, Noida, 201303<br />
              +91-8130497055<br />
              <a href="thewholesalekart.com@gmail.com" className="text-yellow-400 font-semibold">thewholesalekart.com@gmail.com</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
