import React from 'react';
import { FaQuoteLeft } from 'react-icons/fa';
import image1 from '../assets/image1.jpg'; 

const Reviews = () => {
  return (
    <section className="bg-gray-100 py-12">
      <div className="container mx-auto px-6 md:px-40 text-center">
        <h2 className="text-3xl md:text-4xl font-extrabold text-gray-800 mb-8">What Our Clients Say</h2>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="bg-white p-6 shadow-lg transform transition duration-500 hover:scale-105 hover:shadow-2xl">
            <FaQuoteLeft className="text-yellow-400 text-3xl mb-4" />
            <p className="text-gray-600 mb-4">
              "TheWholesaleKart made it incredibly easy to liquidate our excess inventory. The platform is user-friendly, and customer service is top-notch!"
            </p>
            <h3 className="text-yellow-400 font-semibold">John Doe</h3>
            <p className="text-gray-500 text-sm">CEO, Lifestyle Inc.</p>
          </div>

          <div className="bg-white p-6 shadow-lg transform transition duration-500 hover:scale-105 hover:shadow-2xl">
            <FaQuoteLeft className="text-yellow-400 text-3xl mb-4" />
            <p className="text-gray-600 mb-4">
              "I was able to clear out my old inventory efficiently. The global reach of this platform is impressive!"
            </p>
            <h3 className="text-yellow-400 font-semibold">Jane Smith</h3>
            <p className="text-gray-500 text-sm">Founder, Urban Wear</p>
          </div>

          <div className="bg-white p-6 shadow-lg transform transition duration-500 hover:scale-105 hover:shadow-2xl">
            <FaQuoteLeft className="text-yellow-400 text-3xl mb-4" />
            <p className="text-gray-600 mb-4">
              "Highly recommend TheWholesaleKart for anyone looking to efficiently manage and sell off their extra inventory."
            </p>
            <h3 className="text-yellow-400 font-semibold">Emily Johnson</h3>
            <p className="text-gray-500 text-sm">Operations Manager, StyleHouse</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Reviews;
