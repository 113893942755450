import React from 'react';

import brand1 from '../assets/miniclub.png';
import brand2 from '../assets/mothercare.png';
import brand3 from '../assets/nautinati.png';
import brand4 from '../assets/chicco.jpg';
import brand5 from '../assets/truebrown.jpeg';
import brand6 from '../assets/madame.png';
import brand7 from '../assets/ancestry.webp';
import brand8 from '../assets/coverstory.jpeg';

const BrandSection = ({ title, brands }) => {
  return (
    <div className="my-12">
      <h2 className="text-3xl md:text-4xl font-extrabold text-gray-900 mb-4 text-center">{title}</h2>
      <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-8">
        {brands.map((brand, index) => (
          <div
            key={index}
            className="relative group"
          >
            <img
              src={brand.image}
              alt={`Brand ${brand.name}`}
              className="w-full h-auto object-contain rounded-lg shadow-lg transform transition-transform duration-300 ease-in-out group-hover:scale-105"
            />
            <div className="absolute inset-0 bg-black bg-opacity-50 opacity-0 flex items-center justify-center rounded-lg">
              <p className="text-white text-lg font-bold">{brand.name}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const BrandsAssociated = () => {
  const kidsBrands = [
    { name: 'Mini Club', image: brand1 },
    { name: 'Mothercare', image: brand2 },
    { name: 'Nautinati', image: brand3 },
    { name: 'Chicco', image: brand4 },
  ];

  const ladiesBrands = [
    { name: 'True Brown', image: brand5 },
    { name: 'Madame', image: brand6 },
    { name: 'Ancestry', image: brand7 },
    { name: 'Cover Story', image: brand8 },
  ];

  return (
    <div className="bg-gray-100">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
        <BrandSection title="Kids Division" brands={kidsBrands} />
        <BrandSection title="Ladies Division" brands={ladiesBrands} />
      </div>
    </div>
  );
};

export default BrandsAssociated;
