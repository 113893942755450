import React from 'react';
import image2 from "../assets/image2.webp";
import WhatWeDo from '../components/WhatWeDo';

const AboutUs = () => {  
  return (
    <>
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-50 pb-[5.5rem] px-6 md:px-40 pt-10">
        <div className="max-w-7xl mx-auto flex flex-col md:flex-row items-center">
          
          <div
            className="md:w-3/5 mb-8 md:mb-0"
          >
            <h2 className="text-3xl md:text-4xl font-extrabold text-gray-800 leading-snug mb-4">
              <span className='text-yellow-400'>Reasons</span> to count on us.
            </h2>
            <p className="text-gray-600">
            Welcome to TheWholeSaleKart, a pioneering B2B marketplace dedicated to streamlining the inventory liquidation 
            process for lifestyle goods. Based in Noida, we are transforming how surplus inventory is converted into cash by 
            connecting sellers directly with targeted buyers. Our platform eliminates intermediaries, ensuring a transparent 
            and hassle-free experience for both sellers and buyers.
            </p>
            <br />
            <p className="text-gray-600">
            At TheWholeSaleKart, we aim to bring order to the unorganized liquidation industry by providing real-time 
            quotations, smooth negotiation processes, and secure payment and dispatch methods. Our goal is to empower 
            inventory holders—whether they are brands, private labels, or wholesalers—to maximize the realization of their 
            unsold goods. Simultaneously, we offer retailers and wholesalers unparalleled access to a diverse range of products 
            at competitive prices.
            </p>
            <br/>
            <p className='text-gray-600'>
            Join us in revolutionizing the inventory liquidation landscape, where we provide end-to-end solutions from stock 
            inspection to final liquidation, ensuring maximum brand visibility and seamless transactions.
            </p>
          </div>

          <div className="md:w-2/5 flex justify-center items-center relative">
            <img
              src={image2}
              alt="image4"
              className="w-[500px] h-[350px] object-cover shadow-lg md:w-[400px] md:h-[500px]"
            />
          </div>
        </div>
      </div>
      <WhatWeDo/>
    </>
  );
};

export default AboutUs;