import { Link, NavLink } from 'react-router-dom';

import logo from "../assets/logo.png";
import { FaEnvelope, FaMapMarkerAlt, FaPhoneAlt } from 'react-icons/fa';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-10">
      <div className="max-w-7xl mx-auto px-6 md:px-10">
        <div className="flex flex-col md:flex-row justify-between items-start">
          <div className="mb-8 md:mb-9 w-full md:w-1/2">
            <div className="">
              <img src={logo} alt="logo" className="h-15 w-40" />
            </div>
            <p className="text-md mt-2">
            A B2B marketplace dedicated to simplifying inventory liquidation, connecting sellers of surplus goods directly with buyers, ensuring transparency, and delivering the best market value for unsold stock.
            </p>
          </div>
          <nav className="mb-8 md:mb-0 w-full md:w-1/2 flex flex-col md:items-center">
            <ul className="flex flex-col space-y-2 text-md">
              <li><Link to="/" className="hover:underline">Home</Link></li>
              <li><Link to="/about-us" className="hover:underline">About Us</Link></li>
              <li><Link to="/why-us" className="hover:underline">Why us</Link></li>
              <li><Link to="/how-it-works" className="hover:underline">How it works</Link></li>
              <li><Link to="/brands-associated" className="hover:underline">Brands associated with</Link></li>
            </ul>
          </nav>
          <div className="w-full md:w-1/2 text-start">
            <div className="flex flex-col gap-1 space-y-4 mt-10 md:mt-0 mb-8">
              <div className="flex items-center">
                <FaMapMarkerAlt className="text-yellow-400 mr-2 " />
                <span>BL-47, Basement, Sector 116, Noida, 201303</span>
              </div>
              <div className="flex items-center">
                <FaPhoneAlt className="text-yellow-400 mr-2" />
                <span>+91-8130497055</span>
              </div>
              <div className="flex items-center">
                <FaEnvelope className="text-yellow-400 mr-2" />
                <span>thewholesalekart.com@gmail.com</span>
              </div>
              <NavLink
                to="/contact"
                className="bg-yellow-400 hover:bg-yellow-500 text-black px-4 mt-10 md:mt-0 py-2 w-40 text-center"
              >
                Contact us
              </NavLink>
            </div>
          </div>
        </div>
        <div className="flex flex-col md:flex-row justify-between items-center mt-10 border-t border-gray-700 pt-4">
          <p className="text-center md:text-left text-sm mb-4 md:mb-0">
            &copy; {new Date().getFullYear()} The Wholesale Kart. All rights reserved.
          </p>
          <div className="flex space-x-4 text-sm">
            <Link to="/privacy-policy" className="hover:underline">Privacy Policy</Link>
            <Link to="/terms-and-conditions" className="hover:underline">Terms & Conditions</Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;