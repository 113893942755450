import React from 'react';

const TermsConditions = () => {
  return (
    <div className="container mx-auto px-6 lg:px-40 py-12 bg-gray-100">
      <div className="bg-white shadow-lg p-6 lg:p-10">
        <h1 className="text-3xl md:text-4xl font-extrabold mb-8">Terms and Conditions</h1>
        <div className="text-gray-700 space-y-8">
          <div>
            <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
            <p>These Terms and Conditions ("Terms") govern your use of the TheWholesaleKart website <span className='text-yellow-400'>thewholesalekart.com</span> ("Site") ("we," "our," "us"). By accessing or using the Site, you agree to be bound by these Terms. If you do not agree with these Terms, please do not use the Site.</p>
          </div>
          <div>
            <h2 className="text-2xl font-semibold mb-4">2. User accounts</h2>
            <ul className="list-disc ml-6 mt-2 space-y-2">
              <li>
                <strong>Account registration:</strong> To access certain features of the Site, you may be required to create an account. You must provide accurate and complete information when registering.
              </li>
              <li>
                <strong>Account security:</strong> You are responsible for maintaining the confidentiality of your account login information and are fully responsible for all activities that occur under your account.
              </li>
            </ul>
          </div>
          <div>
            <h2 className="text-2xl font-semibold mb-4">3. Use of the site</h2>
            <ul className="list-disc ml-6 mt-2 space-y-2">
              <li>
                <strong>Permitted user:</strong> You may use the Site for lawful purposes and in accordance with these Terms. You agree not to use the Site for any illegal or unauthorized purpose.
              </li>
              <li>
                <strong>Prohibited conduct:</strong> You agree not to engage in any conduct that could harm the Site, our business, or other users. This includes but is not limited to unauthorized access, using the Site to distribute malware, or violating any applicable laws.
              </li>
            </ul>
          </div>
          <div>
            <h2 className="text-2xl font-semibold mb-4">4. Marketplace transactions</h2>
            <ul className="list-disc ml-6 mt-2 space-y-2">
              <li>
                <strong>Listing and Purchasing:</strong> TheWholesaleKart provides a platform for sellers to list their surplus inventory and for buyers to purchase such inventory. All transactions conducted through the Site are the responsibility of the respective sellers and buyers.
              </li>
              <li>
                <strong>Fees and Payments:</strong> Any fees associated with transactions on the Site will be clearly indicated. Payments must be made in accordance with the terms set forth at the time of the transaction.
              </li>
            </ul>
          </div>
          <div>
            <h2 className="text-2xl font-semibold mb-4">5. Intellectual property</h2>
            <ul className="list-disc ml-6 mt-2 space-y-2">
              <li>
                <strong>Ownership:</strong> All content on the Site, including text, graphics, logos, and software, is the property of TheWholesaleKart or its content suppliers and is protected by intellectual property laws.
              </li>
              <li>
                <strong>License:</strong> We grant you a limited, non-exclusive, non-transferable license to access and use the Site for personal and business purposes.
              </li>
            </ul>
          </div>
          <div>
            <h2 className="text-2xl font-semibold mb-4">6. Limitation of liability</h2>
            <ul className="list-disc ml-6 mt-2 space-y-2">
              <li>
                <strong>Disclaimer:</strong> The Site is provided on an "as is" and "as available" basis. We make no warranties or representations about the accuracy or completeness of the Site’s content.
              </li>
              <li>
                <strong>Limitation:</strong> To the fullest extent permitted by law, we shall not be liable for any damages arising out of or in connection with your use of the Site.
              </li>
            </ul>
          </div>
          <div>
            <h2 className="text-2xl font-semibold mb-4">7. Termination</h2>
            <p>We reserve the right to terminate or suspend your access to the Site at our sole discretion, without notice, if you violate these Terms or engage in conduct that we deem harmful to the Site or other users.</p>
          </div>
          <div>
            <h2 className="text-2xl font-semibold mb-4">8. Governing law</h2>
            <p>
            These Terms shall be governed by and construed in accordance with the laws of [Your Country/State], without regard to its conflict of law principles.
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-semibold mb-4">9. Changes to these terms</h2>
            <p>
            We may update these Terms from time to time. Any changes will be posted on this page with an updated revision date.
            </p>
          </div>
          <div>
            <h2 className="text-2xl font-semibold mb-4">10. Contact us</h2>
            <p>
            If you have any questions or concerns about this Privacy Policy, please contact us at 
            </p>
            <p className="mt-2">
              <strong>TheWholesaleKart</strong><br />
              BL-47, Basement, Sector 116, Noida, 201303<br />
              +91-8130497055
              <br />
              BL-47, Basement, Sector 116, Noida, 201303<br />
              +91-8130497055<br />
              <a href="thewholesalekart.com@gmail.com" className="text-yellow-400 font-semibold">thewholesalekart.com@gmail.com</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};


export default TermsConditions;
