import React from 'react';
import { Link } from 'react-router-dom';
import { FaSignInAlt, FaClipboardList, FaPaperPlane, FaPhone } from 'react-icons/fa';
import { SellerGuide } from '../components/SellerGuide';
import { BuyerGuide } from '../components/BuyerGuide';

const sellerSteps = [
  {
    id: 1,
    icon: <FaSignInAlt />,
    title: 'Log to the Website',
    description: 'Start by logging into your account on our website.',
  },
  {
    id: 2,
    icon: <FaClipboardList />,
    title: 'Fill Up the Seller Query Form',
    description: 'Provide your inventory details by filling out the seller form.',
  },
  {
    id: 3,
    icon: <FaPaperPlane />,
    title: 'Submit Query Form',
    description: 'Submit the form for further processing.',
  },
  {
    id: 4,
    icon: <FaPhone />,
    title: 'Request a Call Back',
    description: 'Our team will contact you for more details.',
  },
];

const buyerSteps = [
  {
    id: 1,
    icon: <FaSignInAlt />,
    title: 'Log to the Website',
    description: 'Start by logging into your account on our website.',
  },
  {
    id: 2,
    icon: <FaClipboardList />,
    title: 'Fill Up the Buyer\'s Requirement Form',
    description: 'Provide your purchase requirements by filling out the buyer form.',
  },
  {
    id: 3,
    icon: <FaPaperPlane />,
    title: 'Submit Requirement Form',
    description: 'Submit the form for further processing.',
  },
  {
    id: 4,
    icon: <FaPhone />,
    title: 'Request a Call Back',
    description: 'Our team will contact you for more details.',
  },
];

const HowItWorks = () => {
  return (
    <div className='bg-gray-100'>

    <div className="max-w-7xl mx-auto py-16 px-4 lg:px-8 ">
      <h2 
        className="text-3xl md:text-4xl font-extrabold text-center"
        >
        How It Works
      </h2>

      <p className="text-lg text-center mt-4">
        Whether you’re a seller or a buyer, we’ve made the process simple and efficient. Select your path below.
      </p>

      <BuyerGuide/>
      <SellerGuide/>

      </div>
      </div>
  );
};

export default HowItWorks;
