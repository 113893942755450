import React from 'react'
import Hero from '../components/Hero';
import WhatWeDo from '../components/WhatWeDo';
import Reviews from '../components/Reviews';
import HomeWhyUs from '../components/HomeWhyUs';
import Brands from '../components/Brands';
import { BuyerGuide } from '../components/BuyerGuide';
import { SellerGuide } from '../components/SellerGuide';

export const Home = () => {
  return (
    <div>
        <Hero/>
        <Brands/>
        <WhatWeDo/>
        <HomeWhyUs/>
        <BuyerGuide/>   
        <SellerGuide/>
        <Reviews/>
    </div>
  )
}
