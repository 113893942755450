import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import 'react-toastify/dist/ReactToastify.css';

import Navbar from './components/Navbar';

import HowItWorks from './pages/HowItWorks';
import Contact from './pages/Contact';
import AboutUs from './pages/AboutUs';
import WhyUs from './pages/WhyUs';
import BrandsAssociated from './pages/BrandsAssociated';
import { Home } from './pages/Home';
import Footer from './components/Footer';
import ScrollToTop from './components/ScrollToTop';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsConditions from './pages/TermsAndConditions';
import { BuyItem } from './components/BuyItem';
import { SellItem } from './components/SellItem';

function App() {
  return (
    <Router>
      <ScrollToTop/>
      <Navbar />
      <Routes>
      <Route path="/" element={<Home />} />
        <Route path="/how-it-works" element={<HowItWorks />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/buy-item" element={<BuyItem />} />
        <Route path="/sell-item" element={<SellItem />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/why-us" element={<WhyUs />} />
        <Route path="/brands-associated" element={<BrandsAssociated />} />

        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms-and-conditions" element={<TermsConditions />} />
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
