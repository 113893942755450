import React, { useState, useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../assets/logo.png';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  // Function to toggle the mobile menu
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  // Function to close both the mobile menu and dropdown
  const closeMenu = () => {
    setIsOpen(false);
    setIsDropdownOpen(false);
  };

  // Function to toggle the dropdown
  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  // Close the dropdown if clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const getLinkClass = ({ isActive }) =>
    isActive
      ? 'text-yellow-400 font-bold'
      : 'text-gray-700 hover:text-gray-900';

  return (
    <nav className="bg-white shadow-md sticky top-0 z-50">
      <div className="container mx-auto px-4 lg:px-20 py-3 flex items-center justify-between">
        <NavLink to="/" exact="true">
          <img src={logo} alt="logo" className="h-10 sm:h-12" />
        </NavLink>

        <div className="hidden md:flex space-x-6">
          <NavLink to="/" exact="true" className={getLinkClass}>
            Home
          </NavLink>
          <NavLink to="/how-it-works" className={getLinkClass}>
            How it works
          </NavLink>
          <div className="relative" ref={dropdownRef}>
            <button
              onClick={toggleDropdown}
              className="text-gray-700 hover:text-gray-900 focus:outline-none"
            >
              Get started
            </button>
            {isDropdownOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 shadow-lg">
                <NavLink
                  to="/buy-item"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                  onClick={closeMenu}
                >
                  Post your requirement
                </NavLink>
                <NavLink
                  to="/sell-item"
                  className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                  onClick={closeMenu}
                >
                  Sell your item
                </NavLink>
              </div>
            )}
          </div>
          <NavLink to="/about-us" className={getLinkClass}>
            About us
          </NavLink>
          <NavLink to="/why-us" className={getLinkClass}>
            Why Us
          </NavLink>
          <NavLink to="/brands-associated" className={getLinkClass}>
            Brands associated with
          </NavLink>
          <NavLink to="/contact" className={getLinkClass}>
            Contact
          </NavLink>
        </div>

        <div className="md:hidden">
          <button onClick={toggleMenu} className="text-gray-700 focus:outline-none">
            {isOpen ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            )}
          </button>
        </div>
      </div>

      {isOpen && (
        <div
          className="md:hidden bg-white shadow-lg py-4 absolute w-full z-40"
          style={{ top: '100%' }}
        >
          <div className="flex flex-col space-y-4 px-6">
            <NavLink to="/" exact="true" className={getLinkClass} onClick={closeMenu}>
              Home
            </NavLink>
            <NavLink to="/how-it-works" className={getLinkClass} onClick={closeMenu}>
              How it works
            </NavLink>
            <div className="relative" ref={dropdownRef}>
              <button
                onClick={toggleDropdown}
                className="text-gray-700 hover:text-gray-900 focus:outline-none"
              >
                Get started
              </button>
              {isDropdownOpen && (
                <div className="absolute right-16 w-48 bg-white border border-gray-200 shadow-lg">
                  <NavLink
                    to="/buy-item"
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                    onClick={closeMenu}
                  >
                    Post your requirement
                  </NavLink>
                  <NavLink
                    to="/sell-item"
                    className="block px-4 py-2 text-gray-700 hover:bg-gray-100"
                    onClick={closeMenu}
                  >
                    Sell your item
                  </NavLink>
                </div>
              )}
            </div>
            <NavLink to="/about-us" className={getLinkClass} onClick={closeMenu}>
              About us
            </NavLink>
            <NavLink to="/why-us" className={getLinkClass} onClick={closeMenu}>
              Why Us
            </NavLink>
            <NavLink to="/brands-associated" className={getLinkClass} onClick={closeMenu}>
              Brands associated with
            </NavLink>
            <NavLink to="/contact" className={getLinkClass} onClick={closeMenu}>
              Contact
            </NavLink>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
