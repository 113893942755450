import seller from "../assets/seller-guide.png"

export const SellerGuide = () => {
    return (
<div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8 flex flex-col-reverse md:flex-row items-center">

      <div className="md:w-1/2 mt-8 md:mt-0 md:pr-8">
        <img src={seller} alt="Seller Guide" className="w-full h-auto" />
      </div>

      <div className="md:w-1/2 md:pl-8 text-left">
        <h2 className="text-3xl md:text-4xl font-extrabold text-gray-900 mb-4">Seller Guide</h2>
        <p className="text-lg text-gray-600 border-b-4 border-yellow-400">
        Don’t let unsold, aged, or surplus stock hold you back—convert it into cash with ease. Our liquidation platform helps you quickly connect with interested buyers, allowing you to clear out excess inventory in one or multiple transactions. This lets you focus on growing your business while we handle the rest. Value Shoppe is designed to solve inventory problems, helping businesses turn liabilities into assets across various categories and locations. Submit your inventory today, and we'll broadcast it to potential buyers, ensuring competitive offers. We act fast, pay in cash, and make the best deals possible.
        </p>
      </div>
    </div>
    )}